const en = {
    appTitle: "Applications",
    pages: {
        appsPage: {
            exit: "Exit",
            closeOtherSessions: "Close other sessions"
        },
        inviteRegisterPage: {
            headerSubmit: "Registration confirmation",
            headerInvite: "Registration by invite",
            domain: "Domain",
            name: "Name",
            login: "Login",
            pwd: "Password",
            pwdRepeat: "Repeat password",
            next: "Next",
            errorDifferentPwd: "Passwords are not equal"
        },
        loginPage: {
            header: "Authorization",
            domain: "Domain",
            login: "Login",
            password: "Password",
            buttonLogin: "Log in",
            closeOtherSessions: "close other sessions",
            linkRestorePwd: "Password recovery",
            linkRegister: "Register (sign up)"
        },
        restorePwdPage: {
            header: "Password recovery",
            loginEmail: "Email or login",
            domainRequired: "Domain",
            domainOptional: "Domain (optional)",
            next: "Next"
        },
        resetPwdPage: {
            header: "Password reset",
            pwd: "Password",
            pwdRepeat: "Repeat password",
            next: "Next",
            errorDifferentPwd: "Passwords are not equal"
        },
        registerPage: {
            header: "Registration",
            domain: "Domain",
            name: "Name",
            login: "Login",
            next: "Next"
        }
    },
    stores: {
        dataUtils: {
            successResultTexts: {
                restorePwd: "Password reset link has been sent by e-mail",
                register: "Confirmation link has been sent by e-mail",
                registerSubmit: "Please use new password for authorization",
                inviteSubmit: "Please use new password for authorization",
                resetPwd: "Please use new password for authorization"
            },
            errorCodesTexts: {
                domainNotFound: "Domain not found",
                userNotFound: "User not found",
                invalidData: {
                    invalidEmail: "Invalid e-mail",
                    invalidPwd: "Invalid password"
                },
                accessDenied: "Access denied",
                emailNotFound: "E-mail not found. Please contact your administrator for password change or e-mail setup."
            }
        }
    }
}

export default en;