import React from 'react';
import ReactDOM from 'react-dom';
import Consts from './utils/consts';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

Consts.initializeByBrowser();

document.title = Consts.appTitle;

var rendered = false;

const request = new XMLHttpRequest();
const url = "/rest/v1/public/resources/defaults.json";
request.open('GET', url);
request.addEventListener("readystatechange", () => {
    if (request.readyState === 4) {
        if (request.status === 200) {
            var parsed = JSON.parse(request.responseText);
            if (parsed)
                (global as any).eraDefaultDomain = parsed.defaultDomain;
        }
        else {
        }
        if (!rendered) {
            rendered = true;
            ReactDOM.render(<App />, document.getElementById('root'));
        }
    }
});
request.send();

setTimeout(() => {
    if (!rendered) {
        rendered = true;
        ReactDOM.render(<App />, document.getElementById('root'));
    }
}, 1000);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
